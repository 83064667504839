.menu-svg {
    overflow: visible;
  }
  
  .menu-bar {
    transition: all 0.3s ease;
    transform-origin: center;
  }
  
  .menu-svg .top-bar {
    transform: translateY(0);
  }
  
  .menu-svg .bottom-bar {
    transform: translateY(10px);
  }
  
  
.menu-svg.open .top-bar {
    transform: translateY(5px) rotate(30deg);
  }
  
  .menu-svg.open .bottom-bar {
    transform: translateY(5px) rotate(-30deg);
  }