
.img-nav{
    height: 40px;
}


.nav-link {
    color: #F4F9FC;
    text-decoration: none;
    position: relative;
    display: inline-block; 
    padding-bottom: 2px; 
  }
.nav-link:hover .underline {
    width: 60%;
  }

.underline {
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    width: 0;
    background-color: #FB31A4;
    transition: width 0.3s ease;
  }

  .fullscreen-nav{
flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  }

  

  .scrolledImg {
    border: 1px solid #F4F9FC;
}
.scrolledTitle{
  color:#F4F9FC !important;
}