/*custom fonts*/
@font-face {
    font-family: Centra;
    src: url(./assets/font/CentraNo2-Bold\ \(1\).ttf);
    font-weight:700;
}
@font-face {
    font-family: Centra;
    src: url(./assets/font/CentraNo2-Medium.ttf);
    font-weight:500;
}
@font-face {
    font-family: Centra;
    src: url(./assets/font/CentraNo2-Book.ttf);
    font-weight:400;
}

/*Default CSS*/
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
    scroll-padding-top:75px;
}
body{
    font-weight:400;
    overflow-x:hidden;
    position: relative;
    background-color: #121212 !important;
    color: #fff !important;
    font-family:'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6{
margin: 0;
padding:0;
line-height: no;
}
.span_title::after, .span_title::before{
    content: '+';
    margin: 0px 20px;
}
.bg-white{
    background-color: #FFF;
    color: #5e5e5e;
}
.bg-dark{
    background-color: #212520;
    color: #FFF;
}
.bg-dark p{
    color: #FFFFFFc0;
    line-height: 25px;
}
.title-info-home{
    font-size: 36px;
    
  }
  .border-bottom-info{
    width: 25%;
    height: 1px;
    border-bottom: 1px solid #e5e5e5;
    display: block;
    margin: 0 auto;
  
  }
  .paragraph-info-home{
    font-size: 16px;
  }
  .card-img-overlay{
    visibility: hidden;
  }
  .card:hover .card-img-overlay{
    visibility: visible;
    background-color: #000000c0;
  }
  .notify_animation{
	        animation: shake-tr 1.2s infinite 1s alternate-reverse forwards;            
  }
  .in-up-animation{
	        animation: scale-up-ver-bottom 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  .in-down-animation{
	        animation: scale-up-ver-top 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  .in-left-animation{
    animation: scale-up-hor-right 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  .in-right-animation{
	        animation: scale-up-hor-left 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  .hide-item{
    visibility: hidden;
  }
  .card-img-overlay{
    color: #FFF!important;
  }
  #mark-notify{
    position: absolute;
    font-size: 12px;
    top: 35px;
    right: 80px;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
  }
  /*Vibration Img*/
  @keyframes shake-tr {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 100% 0;
              transform-origin: 100% 0;
    }
    10% {
      -webkit-transform: rotate(1deg);
              transform: rotate(1deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-2deg);
              transform: rotate(-2deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
    80% {
      -webkit-transform: rotate(-1deg);
              transform: rotate(-1deg);
    }
    90% {
      -webkit-transform: rotate(1deg);
              transform: rotate(1deg);
    }
  }
  /*Entrada vertical abajo->arriba*/
  @keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  }
  /*Entrada vertical arriba->abajo*/
  @keyframes scale-up-ver-top {
    0% {
      transform: scaleY(0.4);
      transform-origin: 100% 0%;
    }
    100% {
      transform: scaleY(1);
      transform-origin: 100% 0%;
    }
  }
  /*Entrada horizontal izquierda->derecha*/
  @keyframes scale-up-hor-left {
    0% {
      transform: scaleX(0.4);
      transform-origin: 0% 0%;
    }
    100% {
      transform: scaleX(1);
      transform-origin: 0% 0%;
    }
  }
  /*Entrada horizontal derecha->izquierda*/
  @keyframes scale-up-hor-right {
    0% {
      transform: scaleX(0.4);
      transform-origin: 100% 100%;
    }
    100% {
      transform: scaleX(1);
      transform-origin: 100% 100%;
    }
  }