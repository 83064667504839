.consult-credit {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  }
.consult-credit__content{
    background-color: white;
    border-radius: 20px;
    box-shadow: 1px 3px 30px 14px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 3px 30px 14px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 3px 30px 14px rgba(0,0,0,0.75);

}
.background_color_app{
    background-color: #151515;
    border-radius: 0px;
}
.brd-top{
    border-top: 1px solid #FFFFFF20;
    
}
.text_muted{
    color: #FFFFFF50;
    margin-bottom: 0px;
    margin-top: 0px;
}

.brd-btm{
    border-bottom: 1px solid #FFFFFF20;
}
.h1custom{
    font-size:35px;
    text-align:center;
    margin-bottom: 50px;
    color:black !important;
}
