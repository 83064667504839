column * {
    box-sizing: border-box;
  }
  .wrapper {
    max-width: 85rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  
  .wrapper > div {
    border-radius: 5px;
    padding: 1em;
  }
  
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-gap: 8px;
    grid-auto-rows: minmax(100px, auto);
  }
  .one {
    grid-column: 1 / 2;
    grid-row: 1/3 ;
    display: flex;
      justify-content: center;
      align-items: center;
  }
  .two {
    grid-column: 2 / 3;
    grid-row: 1 ;
    justify-self: center;
    width: 100%;
    height: 6rem;
    
  }
  .three {
    grid-column: 3;
    grid-row: 1/3;
    display: flex;
      justify-content: center;
      align-items: center;
  }
  .four {
    grid-column: 2/3;
    grid-row: 2/4;
    display: flex;
    justify-content: center;
    max-height: 22rem;
  }
 .imgPlanes{
max-width: 25rem;
border-radius: 30px;
  }
  
  @media (max-width: 1100px) {
    .wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
    
  
    .one {
      grid-column: 1 /2;
      grid-row: auto;
    }
  
    .two {
      grid-column: 2 /2;
      grid-row: 1;
      align-self: center;
      min-height: 6rem;
    }
  
    .three {
      grid-column: 1 /2;
      grid-row: auto;
      display: flex;
    }
  
    .four {
      grid-column: 2 /2;
      grid-row: auto;
      max-height: 33rem;
    }
  
  }
  
  @media (max-width: 770px) {
    .wrapper {
      grid-template-columns: repeat(1, 2fr);
    }
    
    .one {
      grid-column: 1 /1;
      grid-row: auto;
    }
  
    .two {
      grid-column: 1 /1;
      grid-row: 1;
      align-self: center;
      min-height: 6rem;
    }
  
    .three {
      grid-column: 1 /1;
      grid-row: auto;
      display: flex;
    }
  
    .four {
      grid-column: 1 /1;
      grid-row: auto;
    }
  }