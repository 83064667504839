.footer {
    background: center/cover no-repeat;
    background-color: #151515;
}

.footer img {
    max-width: 50px;
    border-radius: 10px;
}

.footer-link {
    padding: 8px 10px;
    color: #fff;
    font-size: 15px;
    margin-bottom: 16px;
    display: inline-block;
}
.text-muted-footer{
    color: #FFFFFF58;
    font-weight: lighter;
}