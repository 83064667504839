.videoTop {
  position: relative;
  top: -300px;
  max-width: 80rem;
  margin-bottom: -300px;
}


@media (max-width: 885px) {
  .videoTop {
    position: relative;
    top: -170px;
    max-width: 45rem;
    margin-bottom: -170px;
  }
}

@media (max-width: 620px) {
  .videoTop {
    position: relative;
    top: 20px;
    max-width: 24rem;
    margin-bottom: 20px;
  }
  
}
.icon{
  width:4rem ;
}
.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  margin: 0 auto;
  position: relative;
}

.line {
  width: 100%;
  height: 1px;
  border-top: 2px dotted #fb31a4;
}

.event {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.iconsSteps {
  flex-direction: row;
}

.event:nth-child(1) {
  left: 1%;
}

.event:nth-child(2) {
  left: 23%;
}

.event:nth-child(3) {
  left: 45%;
}

.event:nth-child(4) {
  left: 67%;
}

.event:nth-child(5) {
  right: 0%;
}

/* Default styles for TEXTS */
.TEXTS {
  color: #2f2c57;
  margin-top: 5rem;
}

.text-item {
  max-width: 11rem;
}

.text-item h1 {
  font-size: 1.5rem;
}

.text-item p {
  font-size: 1rem;
}

@media (max-width: 700px) {
  .enrolamientoPasos{
    flex-direction: column;
  }
  .enrolamientoPasos h2, p {
    text-align: center;
  }
  .TEXTS {
    flex-direction: column;
    align-items: center;
  }

  .text-item {
    max-width: 8rem;
    margin: 1rem 0;
  }

  .text-item h1 {
    font-size: 1.25rem;
  }

  .text-item p {
    font-size: 0.875rem;
  }
  .event {
    width: 4rem;
    height: 4rem;
  }
  .icon {
    width: 1.8rem;
  }
}

@media (max-width: 468px) {
  .timeline {
    width: 97%;
  }
  .event {
    width: 3rem;
    height: 3rem;
  }

  .icon {
    width: 2rem;
  }

  .TEXTS {
    margin-top: 2rem;
  }

  .text-item {
    max-width: 6rem;
  }

  .text-item h1 {
    font-size: 1rem;
  }

  .text-item p {
    font-size: 0.75rem;
  }
}
