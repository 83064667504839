.banner_container{
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.tecnocredito{
  padding-left: 25px;
}
@media (min-width: 850px) {
  .tecnocredito {
    padding-left: 50px;
  }
}
@media (min-width: 1100px) {
  .tecnocredito {
    padding-left: 100px;
  }
}